@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

body {
    font-family: 'PT Sans', sans-serif;
}

* {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 100ms;
}

::-webkit-scrollbar {
    background: transparent;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: none;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

@keyframes fade-left {
    from {
        opacity: 0;
        transform: translateX(-10px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.animate-fade-left {
    animation: fade-left 0.5s ease-out;
}


.asideMenu {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
    z-index: 1;
}

.topMenu {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
    z-index: 1;
    position: relative;
}

.active {
    background-color: #4C5165;
    border-radius: 5px;
    color: #e5e7eb;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.active:hover {
    background-color: #4C5165 !important;
}

.noActive {
    background-color: inherit;
    border-radius: 5px;
    color: inherit;
    box-shadow: none;
}