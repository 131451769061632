@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .form input{
        @apply shadow border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-xl focus:border-gray-400
    }

    .form select{
        @apply shadow border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-xl focus:border-gray-400
    }

    .form label{
        @apply block text-gray-700 font-bold mb-2
    }
    .form button{
        @apply bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-6 mt-5 rounded shadow-md
    }
    
    .form{
        @apply shadow-md bg-white rounded px-4 py-7
    }
}

